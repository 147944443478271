import React from "react"

import Layout from "../../components/layout"

const NotFoundPageRu = ({pageContext}) => (
  <Layout language={pageContext.language}>
    <div className={"page-content"}>
      <div className={"container"}>
        <div className="content-wrapper">
          <div className="content">
            <h1>ОШИБКА 404. СТРАНИЦА НЕ НАЙДЕНА</h1>
            <p>Запрашиваемая страница не найдена.</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPageRu
